import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../helpers/AuthContext";

import { apiUrl } from "../config";

function Rechercher2() {
  let { id } = useParams();
  let history = useHistory();
  const [username, setUsername2] = useState("");

  const [listOfPosts, setListOfPosts2] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
    axios
      .get(`${apiUrl}/auth/postpriv/${id}`)
      .then((response) => {
        setUsername2(response.data.username);
      });

    axios
      .get(`${apiUrl}/posts/byuserIdpriv/${id}`)
      .then((response) => {
        setListOfPosts2(response.data);
      });
  }, []);

  const handleSearchTerm = (e) => {
    let value = e.target.value;
    setSearchTerm(value);
    console.log(searchTerm);
  };
  return (
    <div className="grid2">
      <input
        type="text"
        name="searchBar"
        className="searchbar"
        placeholder="Rechercher par Annees"
        onChange={handleSearchTerm}
      ></input>
      <div className="flexshearch">
        <button
          className="change"
          id="change"
          onClick={() => {
            history.push(`/postpriv2/${authState.id}`);
          }}
        >
          Changer de vue
        </button>

        <button
          className="change"
          id="change"
          onClick={() => {
            history.push(`/Rechercher/${authState.id}`);
          }}
        >
          Recherches par Titre
        </button>
      </div>
      <div className="boutonpriv">
        {" "}
        {(authState.username === username || authState.prof === true) && (
          <>
            <div className="flex">
              <div className="listepostsparutilisateur">
                {listOfPosts
                  .filter((value) => {
                    return value.createdAt.toLowerCase().includes(searchTerm);
                  })
                  .map((value, key) => {
                    const date = new Date(value.createdAt);
                    return (
                      <div key={key} className="post3">
                        <div
                          className="title"
                          onClick={() => {
                            history.push(`/postsecondaire/${value.id}`);
                          }}
                        >
                          {" "}
                          {value.title}{" "}
                        </div>
                        <div
                          className="body"
                          onClick={() => {
                            history.push(`/postsecondaire/${value.id}`);
                          }}
                        >
                          {value.postText}
                        </div>
                        <div className="iframdiv">
                          <img src={value.lien}></img>
                        </div>

                        <div className="footer">
                          <div className="textfooter">
                            {new Intl.DateTimeFormat("local").format(date)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Rechercher2;
