import React, { useEffect, useState } from 'react';

function TextLine({ text, delai }) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
       
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, delai); // delai props

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={animate ? 'animadesc' : ''}>
            <h4>{text}</h4>
        </div>
    );
}

export default TextLine;