

const Loader = () => {


return (
  //  ["lds-roller"] est utiliser pour etirer le . car ce sont des modules Css qui sont utilisés
<div className= "load">
<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>
)

};


export default Loader;