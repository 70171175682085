import { useHistory } from "react-router-dom";
import Buttoncomp from "./component/Buttoncomp";


const ChoixTirages = () =>  {
   let history = useHistory(); 



return (
<>
<div className="choixuntirage">

   


<Buttoncomp message={"Cartes standards"} onClick={() => {
                          history.push("/CartesBase");
                        }} className={"ChoixCartesbases"}/>

<Buttoncomp message={"VOS CARTES"} onClick={() => {
                          history.push("/Cartes");
                        }} className={"ChoixCartes"}/>

  
    

</div>

</>
)


}

export default ChoixTirages;