//import des routes
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AuthContext } from "./helpers/AuthContext";
import { useState, useEffect } from "react";
import axios from "axios";

//import des pages
import Rechercher from "./pages/Rechercher";
import Rechercher2 from "./pages/Recherche2";
import "./css/style.css";
import Feed from "./pages/Feed";
import Post from "./pages/Post";
import Post2 from "./pages/Post2"; 
import Post3 from "./pages/Post3"; 
import lecture from "./pages/lecture";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import Fiche from "./pages/Fiche";
import Profile from "./pages/Profile";
import ChangePasswordreq from "./pages/ChangePasswordreq";
import Delete from "./pages/Delete";
import Accueil from "./pages/Accueil";
import Recherche from "./pages/Recherche";
import Postpriv2 from "./pages/Postpriv2";
import PostsPage from "./pages/PostsPage";
import FicheAdmin from "./pages/FicheAdmin";

import SabonnerCreerundeck from "./pages/tirages/deck/SabonnerCreerundeck";
import SabonnerCreerPosts from "./pages/tirages/deck/SabonnerCreerPosts";

//import du formulaire
import FlavorForm from "./pages/Formulaire";
import CreerTirages from "./pages/CreerTirages";

//Choisir un modéle de carte

import FormCartes from "./pages/tirages/modeles/FormCartes.js";

import FormCartesBase from "./pages/tirages/modeles/FormCartesBase.js";

import Cartes from "./pages/Cartes";
import CartesBase from "./pages/CartesBase";
import ChoixTirages from "./pages/ChoixTirages";

//Partie question mes cartes

import QuestionMesCartes from "./pages/tirages/questions/QuestionMesCartes";

import QuestionMesCartesBase from "./pages/tirages/questions/QuestionMesCartesBase";

import QuestionMesCartesRet from "./pages/tirages/questions/QuestionMesCartesRet";

import QuestionMesCartesBaseRet from "./pages/tirages/questions/QuestionMesCartesBaseRet";

import QuestionMesCartesUnique from "./pages/tirages/questions/QuestionMesCartesUnique";

import QuestionMesCartesUniqueRet from "./pages/tirages/questions/QuestionMesCartesUniqueRet";

import QuestionMesCartesBaseUnique from "./pages/tirages/questions/QuestionMesCartesBaseUnique";

import QuestionMesCartesBaseUniqueRet from "./pages/tirages/questions/QuestionMesCartesBaseUniqueRet";

import QuestionMesCartesAlign from "./pages/tirages/questions/QuestionMesCartesAlign";

import QuestionMesCartesBaseAlign from "./pages/tirages/questions/QuestionMesCartesBaseAlign";

import QuestionMesCartesAlignRet from "./pages/tirages/questions/QuestionMesCartesAlignRet";

import QuestionMesCartesBaseAlignRet from "./pages/tirages/questions/QuestionMesCartesBaseAlignRet";

//DECK
import Decks from "./pages/tirages/deck/DeckFedd";

import ComposantPorte from "./pages/ComposantPorte";


import { apiUrl } from "./config";

const history = createBrowserHistory();

function App() {
  const [menu, setMenu] = useState("...");

  const [authState, setAuthState] = useState({
    username: "",
    id: 0,
    photo_profil: "",
    prof: "",
    status: false,
    admin: "",
  });

  useEffect(() => {
    axios
      .get(`${apiUrl}/auth/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            username: response.data.username,
            id: response.data.id,
            admin: response.data.admin,
            prof: response.data.prof,
            photo_profil: response.data.photo_profil,
            status: true,
          });
        }
      });
  }, []);

  //Deconnexion
  const logout = () => {
    localStorage.removeItem("accessToken");

    setAuthState({
      username: "",
      prof: "",
      id: 0,
      photo_profil: "",
      admin: "",
      status: false,
      style: "",
    });

    history.push("/");

    window.location.reload(true);
  };

  //State et fonction pour injection de stye / cacher la barre de menu
  const [style, setStyle] = useState("barredenavigation");

  function cacher(result) {
    if (style === "barredenavigation") {
      setMenu("Menu");
      result = setStyle("cacher");
    } else {
      setMenu("...");
      result = setStyle("barredenavigation");
    }

    return result;
  }

  return (
    <section className="container">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <nav className={style}>
            <div className="apparitionbarrecontexte">
              <ul>
                {" "}
                <li id="button" className="bouton">
                  {authState.status && (
                    <button onClick={logout}>⚪ Déconnexion</button>
                  )}
                </li>
                <li id="username" className="username">
                  {!authState.prof && authState.username && (
                    <>
                      <Link to={`/FicheAdmin/${authState.id}`}>
                        🍀
                        {authState.username}
                      </Link>
                    </>
                  )}

                  {authState.prof && (
                    <>
                      <Link to={`/FicheAdmin/${authState.id}`}>
                        🍀
                        {authState.username}
                      </Link>
                    </>
                  )}
                </li>
                <li className="public">
                  {authState.status && authState.prof && (
                    <>
                      <Link to="/decks">🎴Deck</Link>
                    </>
                  )}

                  {authState.status && !authState.prof && (
                    <>
                      <Link to="/SabonnerCreerundeck">
                        🎴S'abonner et créer un Deck
                      </Link>
                    </>
                  )}
                </li>
                <li className="public">
                  {authState.status && (
                    <>
                      <Link to="/ChoixTirages">🃏Tirages</Link>
                    </>
                  )}
                </li>
                <li className="public">
                  {authState.status && (
                    <Link to="/PostsPage">​📜​Mes Posts</Link>
                  )}
                </li>
                <li className="creerprive">
                  {" "}
                  {authState.status && authState.prof && (
                    <Link to="/CreerTirages">​🧙​Créer posts</Link>
                  )}
                  {authState.status && !authState.prof && (
                    <Link to="/SabonnerCreerPosts">
                      ​🧙S'abonner et ​Créer des posts
                    </Link>
                  )}
                </li>
                <li>{authState.status && <Link to="/Feed">☺️​Feed</Link>}</li>
              </ul>
            </div>

            {!authState.status && (
              <>
                {" "}
                <ul className="connexion">
                  <li>
                    {" "}
                    <Link to="/login" className="li">
                      {" "}
                      <div>☀️</div>
                      <div>Connexion</div>
                    </Link>
                  </li>

                  <>
                    <li>
                      <Link to="/registration" className="li">
                        <div>💎</div> <div>S'enregistrer</div>{" "}
                      </Link>
                    </li>
                  </>

                  <li>
                    <Link to="/Accueil" className="li">
                      {" "}
                      <div>🏛</div>
                      <div>Accueil</div>
                    </Link>
                  </li>
                </ul>
              </>
            )}
            <div></div>
          </nav>
          <div>
            {authState.status && (
              <button onClick={cacher} id="bouton-cacher">
                {menu}
              </button>
            )}{" "}
          </div>
          <Switch>
            <Route
              path="/SabonnerCreerundeck"
              exact
              component={SabonnerCreerundeck}
            />
            <Route
              path="/SabonnerCreerPosts"
              exact
              component={SabonnerCreerPosts}
            />

            <Route path="/Feed" exact component={Feed} />
            {/* Partie Recherhe d'un post*/}

            <Route path="/Rechercher/:id" exact component={Rechercher} />
            <Route path="/recherche2" exact component={Recherche} />
            <Route path="/Rechercher2/:id" exact component={Rechercher2} />

            {/* Partie formulaire */}
            <Route path="/FlavorForm" exact component={FlavorForm} />

            {/* Partie choix modèles de cartes */}

            <Route path="/FormCartes" exact component={FormCartes} />

            <Route path="/FormCartesBase" exact component={FormCartesBase} />

       

            <Route path="/ChoixTirages" exact component={ChoixTirages} />
            <Route path="/CreerTirages" exact component={CreerTirages} />

            <Route
              path="/ComposantPorte/:id"
              exact
              component={ComposantPorte}
            />

            <Route path="/PostsPage" exact component={PostsPage} />
            <Route path="/Cartes" exact component={Cartes} />

            <Route path="/CartesBase" exact component={CartesBase} />

            {/* Partie questions Mes cartes  */}
            <Route
              path="/QuestionMesCartes"
              exact
              component={QuestionMesCartes}
            />

            <Route
              path="/QuestionMesCartesBase"
              exact
              component={QuestionMesCartesBase}
            />

            <Route
              path="/QuestionMesCartesBase"
              exact
              component={QuestionMesCartesBase}
            />

            <Route
              path="/QuestionMesCartesBaseRet"
              exact
              component={QuestionMesCartesBaseRet}
            />

            <Route
              path="/QuestionMesCartesAlign"
              exact
              component={QuestionMesCartesAlign}
            />

            <Route
              path="/QuestionMesCartesAlignRet"
              exact
              component={QuestionMesCartesAlignRet}
            />

            <Route
              path="/QuestionMesCartesAlignBase"
              exact
              component={QuestionMesCartesBaseAlign}
            />

            <Route
              path="/QuestionMesCartesAlignRetBase"
              exact
              component={QuestionMesCartesBaseAlignRet}
            />

            <Route
              path="/QuestionMesCartesRet"
              exact
              component={QuestionMesCartesRet}
            />

            <Route
              path="/QuestionMesCartesUnique"
              exact
              component={QuestionMesCartesUnique}
            />

            <Route
              path="/QuestionMesCartesUniqueRet"
              exact
              component={QuestionMesCartesUniqueRet}
            />

            <Route
              path="/QuestionMesCartesUniqueBase"
              exact
              component={QuestionMesCartesBaseUnique}
            />

            <Route
              path="/QuestionMesCartesUniqueRetBase"
              exact
              component={QuestionMesCartesBaseUniqueRet}
            />

            {/* Partie des pages */}

            {/* Creer un post */}

             <Route path="/post3/:id" exact component={Post3} /> 

            {/* CRUD posts */}
            <Route path="/profile/:id" exact component={Profile} />
            <Route path="/FicheAdmin/:id" exact component={FicheAdmin} />

            <Route path="/fiche/:id" exact component={Fiche} />

            <Route path="/post/:id" exact component={Post} />
            <Route path="/postsecondaire/:id" exact component={Post2} />
            <Route path="/postpriv2/:id" exact component={Postpriv2} />
            <Route path="/lecture/:id" exact component={lecture} />
            <Route path="/delete" exact component={Delete} />

            {/* Users */}

            <Route
              path="/changepasswordreq"
              exact
              component={ChangePasswordreq}
            />

            <Route path="/registration" exact component={Registration} />
            <Route path="/login" exact component={Login} />
            <Route path="/Accueil" exact component={Accueil} />
            <Route path="/" exact component={Accueil} />

            {/* Partie du DECK  */}

            <Route path="/decks" exact component={Decks} />

            <Route path="*" exact component={PageNotFound} />
          </Switch>
        </Router>
      </AuthContext.Provider>
    </section>
  );
}

export default App;
