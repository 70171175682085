import React from "react";
import { useHistory } from "react-router-dom";

function CarteUniqueMod({ link, Deck }) {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: link,
      state: { Deck }
    });
  };

  return (
    <div className="elementencartesligne">


    <div className="carteunique" onClick={handleClick}>
    
    
      <div className="cartecpres">🎴</div>
    
    
    
    <p>Tirage d'une seule carte à l'endroit</p>
    
    </div>
  
    </div> 











  );
}

export default CarteUniqueMod;
