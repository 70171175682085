
import Buttoncomp from "./Buttoncomp";




const Navbarre = ({ setSelectedDeck, selectedDeck }) => {
  const handleDeckClick = (deckNumber) => {
    setSelectedDeck(deckNumber);
  };

  const handleDeckClickNumber = (deckNumber) => {
    setSelectedDeck(deckNumber);
  };
/* 
  console.log("JE SUIS LA NAVBARRE") */
  return (
    <>   
    

      <div className="navbarre">
        
        <Buttoncomp
     
          title={"Bouton de Deck 1"}
          message={"Deck 1"}

          className={selectedDeck === 1 ? "active" : "active2"}
        
          onClick={() => {
            handleDeckClickNumber(1);
          }}
        >
         
        </Buttoncomp>

        {/* Autres boutons de deck ici */}

        <Buttoncomp
          title={"Bouton de Deck 1"}
          className={selectedDeck === 2 ? "active" : "active2"}
          message={"Deck 2"}
          onClick={() => {
            handleDeckClickNumber(2);
          }}
        >
         
        </Buttoncomp>

        <Buttoncomp
          title={"Bouton de Deck3 "}
          className={selectedDeck === 3 ? "active" : "active2"}
          message={"Deck 3"}
          onClick={() => handleDeckClick(3)}
        >
        message={"Deck 3"}
        </Buttoncomp>

        <Buttoncomp
          title={"Bouton de Deck4 "}
          className={selectedDeck === 4 ? "active" : "active2"}
          message={"Deck4 "}
          onClick={() => {
            handleDeckClickNumber(4);
          }}
        >
        
        </Buttoncomp>

        <Buttoncomp
          title={"Bouton de Deck5 "}
          className={selectedDeck === 5 ? "active" : "active2"}
          message={"Deck5 "}
          onClick={() => handleDeckClick(5)}
        >
        
        </Buttoncomp>
      </div>
    </>
  );
};

export default Navbarre;
