import React, { useEffect, useState, useRef } from "react";

import nuage from "../logos/nuageaccu.png";
import dreamcatcher from "../logos/favicon.jpg";
import image from "../logos/attrapereve.gif"
import Video from "../pages/component/Video";
import Video2 from "../pages/component/Video2";
import Audio from "../pages/component/Audio";
import TextLine from "./component/TextLine";

function Accueil() {

useEffect(()=> {
 

  const token = window.localStorage.getItem("accessToken"); // Vérifier si un token est déjà stocké dans le local storage
  if (!token) {
    const authHeader = window.location && window.location.href.split('Bearer ')[1]; // Récupérer le token dans le header de la requête HTTP
    if (authHeader) {
      window.localStorage.setItem("accessToken", authHeader); // Stocker le token dans le local storage
    }
  }
  import('aos').then((Aos) => {
    Aos.init({
      duration: 40000
    });
  });
  import('aos/dist/aos.css');



},[])
const [isInViewport, setIsInViewport] = useState(false);
const ref = useRef(null);

useEffect(() => {
    const handleScroll = () => {
        if (ref.current) {
            const top = ref.current.getBoundingClientRect().top;
            const bottom = ref.current.getBoundingClientRect().bottom;

            // Vérifier si l'élément est dans la zone visible de l'écran
            if (top >= 0 && bottom <= window.innerHeight) {
                setIsInViewport(true);
            } else {
                setIsInViewport(false);
            }
        }
    };

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Vérifier initialement au chargement de la page
    handleScroll();

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);
const nuageRef = useRef(null);
const [isMouseTracking, setIsMouseTracking] = useState(false);
useEffect(() => {
  const handleMouseMove = (e) => {
    if (isMouseTracking) {
      const nuageElement = nuageRef.current;

      // Calculer la position relative de la souris par rapport à l'élément parent
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      // Définir la nouvelle position de l'image en fonction de la position de la souris
      nuageElement.style.left = `${mouseX}px`;
      nuageElement.style.top = `${mouseY}px`;
    }
  };

  // Ajouter un écouteur d'événements pour détecter les mouvements de la souris
  document.addEventListener("mousemove", handleMouseMove);

  return () => {
    // Nettoyer l'écouteur d'événements lors du démontage du composant
    document.removeEventListener("mousemove", handleMouseMove);
  };
}, [isMouseTracking]);


const handleImageClick = () => {
  setIsMouseTracking(!isMouseTracking); // Inverser le suivi de la souris
};


  return ( 
  
<div className="container-fond"> 
<div>
   <div className="stars"></div><div className="stars3"></div>

   </div>
 
  <div className="flexaccueil">
    
  <div className="structfilante">
   <div className="filantes">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    </div>
     </div>
     <div className="" alt="lecture de l'audio"><Audio /></div>
    
    <section className="top">

   
 <div className="image">


   
<a href="#ancre">
<img src={dreamcatcher} id="dreamcatcheraccu" onClick={handleImageClick}  data-aos="fade" alt="Un attrappe rêve derrière lequel on voit passer des étoiles filantes"/> 
</a>
</div>



    <h1 className="animdiv">
 <div>1</div>
 <div>R</div>
 <div>E</div>
 <div>V</div>
 <div>E</div>
 
 </h1>
         
         
         
         
         <h2 className="animdiv2">
 <div>Le </div>
 <div> Site</div>
 <div>Des </div>
  <div> Reveurs</div> 
 

 
 </h2> 
 
 

 
 
 
 <img ref={nuageRef} src={nuage} alt="un nuage se trouve en dessous du logo" onClick={handleImageClick}  id="nuage" style={{ position: "relative" }} />
 
<div>
<h3 className='animaradius fade' id="rech" data-aos="fade" data-aos-duration="3000">
  Rechercher
  <img src={image} alt="un capteur de rêve apparaît" />
</h3>

</div> 
     <div id="marge"><h3 data-aos="flip-down"data-aos-duration="3000" >Rever</h3></div>
 <div>
   <h3  className='animadesc' id="decouvrir" data-aos="flip-down" data-aos-duration="3000">Découvrir</h3></div> 
   <div id="marge"><h3 data-aos="fade-down"data-aos-duration="3000" >Esperer</h3></div>
   
  
</section> 

{/* Fin section haute---------------------------------------------------------------------------------------------------------- */}


 <section className="middle">
 
 <main className="textmain"> 
{/*  <div data-aos="fade-down-right" data-aos-duration="3000" ref={ref} className={isInViewport ? 'animadesctitre' : ''}> */}
 <div data-aos="fade-down-right" data-aos-duration="3000">
 <h4 >
   <TextLine delai={100} text="Pourquoi ne pas disposer d'outils pour se coacher, etes-vous prêt à vous tirer les cartes de vos souhaits ?" /> 
   </h4>
</div>

<div>
  <h4 data-aos="fade" data-aos-duration="3000">
<TextLine delai={4500}  text="Les enregistrer et les conserver pour les comparer et les étudiers, entourez de vos amis ou bien du professionnels de votre choix." /> 
</h4>
</div>




<div data-aos="fade-down-right" data-aos-duration="3000">
  <h4 >
<TextLine delai={6500}  text="Trouver la clé des songes est une quête à laquelle l'homme s'emploit depuis très longtemps. " /> 
</h4>
</div>


<div data-aos="fade-down-right" data-aos-duration="3000">

  <h4>
<TextLine delai={20500}  text="« Que disent vos tirages? » et « Que disent vos rêves ? »" /> 
</h4>
</div>


<div  data-aos="fade-down-right" data-aos-duration="3000" >
<div>
<h4>Cette application peut être utilisé avec toutes sortes de cartes, comme par exemple des Oracles, pour la divination, pour afficher des images qui permettent d'engager une conversation ou démêler une situation. </h4></div>
<div className="column"><h4> Je l'utilise avec mes enfants et ils l'adorents, à tout âge il peuvent s'expimer autour des images qui apparaisssent à l'écran,</h4></div>
<div ><h4>  ce qui développe leur imaginations et leur sens de la communication.</h4></div>

<div className="column"><h4>Une chose est sur : C'est un vrai coffre au trésor permettant d'aller à la rencontre d'une personne ou de soi même ! </h4></div>

</div>
</main>




<div className='anima' data-aos="flip-down" id="aller" data-aos-duration="3000"><h4>Aller vers</h4></div> 


<div id="soi"><h4 id="soi" data-aos-duration="3000">Soi</h4></div>
<div className='animadesc' data-aos="zoom-in" data-aos-duration="3000"> 

<h4>
Le site WEB 1REVE permet de faire VOS propres recherches sur des sujets psychologiques et aide aux dévellopement des facultés intellectuelles 

</h4>
</div>

<div className="video" data-aos="zoom-in" data-aos-duration="3000"alt="des cartes apparaissent lorsque le pointeur de la souris, click">


              <Video />
          
              </div>
           
              
              <div className="animadesc"  data-aos="zoom-in" data-aos-duration="3000"> <h4>Vous pouvez organiser et conserver tout ce qui ce passe</h4></div>

              <div className="video"  data-aos="zoom-in" data-aos-duration="3000" alt="Une image avec un tirage de carte est publié sur un site de réseau social">
              <Video2 />
          
              
</div>

<div>
  <h4 data-aos="fade" data-aos-duration="3000">
<TextLine delai={4500}  text="La santée c'est parfois élaborer un plan de suivi continu et personnalisé." /> 
</h4>
</div>
   
 <div className='animadesc bounce' data-aos="bounce" data-aos-duration="3000">
  <ul>
<li> <h4>Site de Coaching et Auto-Coaching </h4></li>
<li> <h4>Choisissez vos cartes et vos tirages</h4></li>
<li> <h4>Utilisation de cartes libres de droits dès l'inscription</h4></li>

<li> <h4>Lieux d'échanges sur le sujet des tirages de cartes et des rêves</h4></li>

<li> <h4>Espace permettant d'enregistrer vos tirages et vos rêves ainsi qu'une fiche de suivi personnalisée</h4></li>





  </ul>
  </div>  
</section>

{/* Fin section moyenne---------------------------------------------------------------------------------------------------------- */}


 <section className="bas">


 <div className="footercomposant">
       
       <span className=" fly">
              <a href="https://www.facebook.com/aurelien.monceau.1"> <i className='fab fa-facebook'/>
       Facebook </a>
      
       </span>
  {/*      <span>
       <a href="https://twitter.com/AurelienMonceau"> <i className='fab fa-twitter'/>
       Twitter </a>
       </span> */}
       <span className=" fly">
      
       <a href="mailto:aurelien.monceau@gmail.com">
       ✉️Email</a>
       
       
       
       
       </span>
      
       
       
           
       </div>
 </section>
 
{/* Fin section basse---------------------------------------------------------------------------------------------------------- */}
 
</div>  
</div>
  )
}

export default Accueil
