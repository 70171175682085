import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Formulairecartes from "../../Formulairecartes";
import { apiUrl } from "../../../config";
import { useLocation } from "react-router-dom";
function QuestionMesCartesBase( ) {

  //Récupérer la valeur de la props Deck depuis un State location
  const location = useLocation();
  const Deck = location.state && location.state.Deck;

  let history = useHistory();

  const [reponse, Setreponse] = useState([]);

  const [cartes, Setcarte] = useState([]);

  const [Background, SetBackground] = useState([]);

  const [AffichageBackground, SetAffichageBackground] = useState([]);

  const [PageQuestion, SetPageQuestion] = useState(true);

  const [PageReponse, SetPageReponse] = useState();

  const [Mondeck, SetMondeck] = useState(false);

  const [Mondeckimages, SetMondeckimages] = useState();
  const [Mondeckimages2, SetMondeckimages2] = useState();
  const [Mondeckimages3, SetMondeckimages3] = useState();
  const [Mondeckimages4, SetMondeckimages4] = useState();
  const [Mondeckimages5, SetMondeckimages5] = useState();


  //data de la réponse

  const data = async () => {
    await axios
      .get(
        `${apiUrl}/postimages/liredeckBase/${Deck}}`,

        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        {
          !Mondeck && !Mondeck && SetMondeck(response.data.length);
        }

        /*   Set Mondeck  */

        const aleachoix = Math.floor(Math.random() * Mondeck);
        const aleachoix2 = Math.floor(Math.random() * Mondeck);
        const aleachoix3 = Math.floor(Math.random() * Mondeck);
        const aleachoix4 = Math.floor(Math.random() * Mondeck);
        const aleachoix5 = Math.floor(Math.random() * Mondeck);

        SetMondeckimages(response.data[aleachoix].lien);
        SetMondeckimages2(response.data[aleachoix2].lien);
        SetMondeckimages3(response.data[aleachoix3].lien);
        SetMondeckimages4(response.data[aleachoix4].lien);
        SetMondeckimages5(response.data[aleachoix5].lien);

        return;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  useEffect(() => {
    localStorage.removeItem("Maquestion");
    const fetchData = async () => {
      try {
        if (!localStorage.getItem("accessToken")) {
          history.push("/login");
        }

        const responseImages = await axios.get(
          `${apiUrl}/postimages/lireimagesdosBase/${Deck}`,
          {
            headers: { accessToken: localStorage.getItem("accessToken") },
          }
        );

        const images = responseImages.data;

        const responseBackground = await axios.get(
          `${apiUrl}/postimages/lirebackgroundBase/${Deck}`,
          {
            headers: { accessToken: localStorage.getItem("accessToken") },
          }
        );

        const backgrounds = responseBackground.data;

        Setreponse(images);
        SetBackground(backgrounds);
        Setcarte(images[0]);
        SetAffichageBackground(backgrounds[0]);

        data();
      } catch (error) {
        if (error.response) {
          // Le serveur a répondu avec un statut différent de 2xx exemple erreur 200
          console.error(
            `Erreur ${error.response.status}: ${error.response.data.message}`
          );
        } else if (error.request) {
          // La requête a été envoyée mais pas de réponse a été reçue
          console.error("Pas de réponse du serveur");
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.error(
            `Erreur de configuration de la requête: ${error.message}`
          );
        }
      }
    };

    fetchData();
  }, [Mondeck]);

  const img = Mondeckimages;
  const img2 = Mondeckimages2;
  const img3 = Mondeckimages3;
  const img4 = Mondeckimages4;
  const img5 = Mondeckimages5;

  var recupere = localStorage.getItem("Maquestion");

  function handleFormSubmit(event) {
    event.preventDefault();
  }

  return (
    <React.Fragment>
      <>
        {PageQuestion && (
          <div className="fondtirage">
            <div className="boutonnavcartes">
              <button
                onClick={() => {
                  history.push(`/FormCartesBase`);
                }}
              >
                MODELES
              </button>

              <button
                onClick={() => {
                  history.push(`/CartesBase`);
                }}
              >
                CARTES
              </button>
            </div>
            <div className="backgroundcartesperso">
              {AffichageBackground && AffichageBackground.lien && (
                <img src={Background[0].lien} id="imagebackground" />
              )}

              <div className="ajustement">
                <div className="question">
                  <span>
                  <Formulairecartes
                      onSubmit={handleFormSubmit}
                      SetPageQuestion={SetPageQuestion}
                      SetPageReponse={SetPageReponse}
                    />
                  </span>

                  <div className="haut">
                    {cartes && cartes.lien && (
                      <img src={reponse[0].lien} alt="Carte" />
                    )}
                  </div>

                  <div className="centre ">
                    {cartes && cartes.lien && (
                      <>
                        <img src={reponse[0].lien} alt="Carte" />

                        <img src={reponse[0].lien} alt="Carte" />

                        <img src={reponse[0].lien} alt="Carte" />
                      </>
                    )}
                  </div>
                  <div className="bas">
                    {cartes && cartes.lien && (
                      <img src={reponse[0].lien} alt="Carte" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* La réponse  */}

        {!PageQuestion && (
          <>
            <div className="boutonnavcartes">
            <button
                onClick={() => {
                  SetPageQuestion((PageQuestion) => !PageQuestion);
                  localStorage.removeItem("Maquestion");
                  data();
                }}
              >
                QUESTION
              </button>
            </div>
            <div className="fondtirage2">
              <div className="backgroundreponseperso">
                {AffichageBackground && AffichageBackground.lien && (
                  <img src={Background[0].lien} id="imagebackground" />
                )}

                <div className="ajustement">
                  <div className="reponse">
                    <span>
                      {recupere}
                    </span>

                    <div className="animcroix">
                      <div className="haut">
                        <img src={img} alt="" />
                      </div>

                      <div className="centre">
                        <img src={img2} alt="" />

                        <img src={img3} alt="" />

                        <img src={img4} alt="" />
                      </div>
                      <div className="bas">
                        <img src={img5} alt="" />
                      </div>
                    </div>

                    <div className="animcroix2">
                      <div className="haut">
                        {cartes && cartes.lien && (
                        
                          <img src={reponse[0].lien} alt="Carte" />
                         
                      
                        )}
                      </div>

                      <div className="centre">
                        {cartes && cartes.lien && (
                          <>
                            <img src={reponse[0].lien} alt="Carte" />

                            <img src={reponse[0].lien} alt="Carte" />
                            <img src={reponse[0].lien} alt="Carte" />
                          </>
                        )}
                      </div>
                      <div className="bas">
                        {cartes && cartes.lien && (
                          <img src={reponse[0].lien} alt="Carte" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  );
}

export default QuestionMesCartesBase;
