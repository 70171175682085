import React from "react";

function BoutonOk({ SetPageQuestion, SetPageReponse }) {
  function handleSubmit(event) {
    event.preventDefault();
    SetPageQuestion((PageQuestion) => !PageQuestion);
    SetPageReponse((PageReponse) => !PageReponse);
  }

  return (
    <button
   
      className="boutonanim"
      value="OK"
      onClick={handleSubmit}
    >
      OK
    </button>
  );
}

export default BoutonOk;
