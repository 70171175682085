
import React from "react";


const SabonnerCreerundeck = () => {
 




  return (
    <>

<div className="createPostPageDeckDemo">
        <div className="formContainer2">

          <div className="contact"><a HREF="mailto:aurelien.monceau@gmail.com">Contactez-moi</a> </div>
      
   
    
<div className="demo">Une vidéo de démo est en cours de création concernant la création d'un posts </div>
       </div>  </div>

     
        
    
            

      </>
  );
};

export default SabonnerCreerundeck;
