import React, { Component } from "react";
import ReactPlayer from "react-player";
import video from "../../logos/tirage.mp4";

class Video extends Component {
  render() {
    return (
      <div className="videoplayer">
        <ReactPlayer
          width="78vw"
          height="100%"
          muted
         

          url={video}
          controls
          autoPlay={false}
         loop
        />
      </div>
    );
  }
}

export default Video;
