import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../helpers/AuthContext";
import Buttoncomp from "../../component/Buttoncomp";
import ConfirmationModale from "../../UI/ConfirmationModale";
import ConfirmationModalmodif from "../../UI/ConfirmationModalemodif";

import { apiUrl } from "../../../config";

const AffichageDeck = ({ deckstate, deletePost, setMaj, SelectedDeck }) => {
  let history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, []);

  const [tailledufichier, settailledufichier] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [file, setFile] = useState();
  const [confirmationModal, setconfirmationModal] = useState({});
  const [confirmationModal2, setconfirmationModal2] = useState({});
  const [titre, settitre] = useState("");
  const [text, setText] = useState("");
  const [idamodifmodal, setidamodifmodal] = useState("");
  const [idamodifmodal2, setidamodifmodal2] = useState("");

  const [InputOn, setInputOn] = useState(false);
  const [buttonoff, setbuttonoff] = useState(true);
  const [Idamodif, setIdamodif] = useState("");
  const [maj2, setMaj2] = useState(false);

  const ModifPost = async (deckstate) => {
    setIdamodif(deckstate.id);
    setInputOn((InputOn) => !InputOn);
    setbuttonoff((buttonoff) => !buttonoff);
  };

  const confirmationModalHandler = async (deckstate) => {
    setconfirmationModal({
      title: "Confirmer de la suppression du message ?",

      message: `Voulez-vous vraiment supprimer ce poste qui à pour titre : ${deckstate.title} ? `,

      lien: deckstate.lien,
    });

    setidamodifmodal(deckstate.id);

    return;
  };

  const confirmationModalHandler2 = async (deckstate) => {
    setconfirmationModal2({
      title: "Confirmer la modification du message ?",

      message: `Voulez-vous vraiment modifier ce poste qui à pour titre : ${deckstate.title} ? `,

      lien: deckstate.lien,
    });

    setidamodifmodal2(deckstate.id);

    return;
  };

  const { authState } = useContext(AuthContext);



  const submit = async (deckstate) => {
    try {
      const ancienfichier = deckstate.lien.toString();

   console.log(ancienfichier,"ancienfichier")

      const id = authState.id;
      const formData = new FormData();
      formData.append("image", file);
      formData.append("postText", text);
      formData.append("title", titre);
      formData.append("ancienfichier", ancienfichier);
      formData.append("postId", deckstate.id);

      //Je vais chercher id dans le context
      formData.append("iduser", id);
//Voir si cette partie est utiliser : 1 est possible
      formData.append("numberofdeck", 1);


      const result = await axios.put(
        `${apiUrl}/postimages/`,

        formData,

        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
            ancienfichier,
            "x-deck-number": SelectedDeck,
            "x-deck-numberasupr":   ancienfichier, 
          },
        }
      );

      /*
       */

      setbuttonoff((buttonoff) => !buttonoff);
      setInputOn((InputOn) => !InputOn);
      setconfirmationModal2((confirmationModal2) => !confirmationModal2);
      setconfirmationModal((confirmationModal) => !confirmationModal);
      setMaj((maj) => !maj);
      return result.data;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  };

  const fileSelected = (event) => {
    const filesize = event.target.files[0].size;

    if (filesize > 1000000) {
      console.log(filesize);
      alert("La fichier est trop volumineux il doit faire au maximum 800 KO");
      setIsSuccess(false);
      settailledufichier(filesize);
      return;
    } else {
      const file = event.target.files[0];

      setIsSuccess(true);
      setFile(file);
      settailledufichier(filesize);
      console.log(filesize);
      console.log("FICHIER OK, peut être téléchargé  ");
    }
  };

  useEffect(() => {
    setMaj((maj) => !maj);
  }, [maj2]);

  const orderDisplayMessage =
    deckstate &&
    deckstate.sort((a, b) => {
      return b.id - a.id;
    });

  return (
    <>
      {" "}
      <div className="imagedeck">
        {orderDisplayMessage &&
          orderDisplayMessage.map((deckstate, key) => {
            //Map argument de tableau
            return (
              <div key={key} className="lignedeck">
                {confirmationModal && idamodifmodal === deckstate.id && (
                  <ConfirmationModale
                    title={confirmationModal.title}
                    message={confirmationModal.message}
                    Onconfirm={() => setconfirmationModal(null)}
                    OnconfirmDelete={() => deletePost(deckstate)}
                    lien={confirmationModal.lien}
                  />
                )}

                {confirmationModal2 && idamodifmodal2 === deckstate.id && (
                  <ConfirmationModalmodif
                    title={confirmationModal2.title}
                    message={confirmationModal2.message}
                    Onconfirm={() => setconfirmationModal2(null)}
                    OnconfirmDelete={() => submit(deckstate)}
                    lien={confirmationModal2.lien}
                  />
                )}

                <form onSubmit={submit}>
                  
                  <label>
                    <p className="titre">Titre </p>{" "}
                  </label>
                  <p className="titre">{deckstate.title}</p>

                  {InputOn && Idamodif === deckstate.id && (
                    <input
                      value={titre}
                      onChange={(e) => settitre(e.target.value)}
                      type="text"
                      required
                      placeholder={deckstate.title}
                      minLength="4"
                      maxLength="250"
                      size="100"
                    />
                  )}
                  <p className="description2">Description </p>
                  <p className="description2">{deckstate.postText} </p>

                  {InputOn && Idamodif === deckstate.id && (
                    <textarea
                      value={text}
                      onChange={(i) => setText(i.target.value)}
                      type="text"
                      id="text"
                      minLength="4"
                      maxLength="2250"
                      size="250"
                      placeholder={deckstate.postText}
                    />
                  )}
                  <div className="number">n°{key + 1}</div>
                  <img src={deckstate.lien} />

                  {InputOn && Idamodif === deckstate.id && (
                    <input
                      onChange={fileSelected}
                      type="file"
                      accept="image/*"
                      id="image"
                      required
                      placeholder="choisir un fichier"
                    />
                  )}

                  <div className="alignement">
                    {!InputOn && (
                      <Buttoncomp
                        className="buttonglob_css"
                        onClick={() => confirmationModalHandler(deckstate)}
                        message={"Suprimer"}
                      />
                    )}

                    {InputOn && Idamodif === deckstate.id  &&(
                      <Buttoncomp
                        id={deckstate.id}
                        className="buttonglob_css"
                        type="submit"
                        onClick={() => confirmationModalHandler2(deckstate)}
                        message={"Envoyer"}
                      />
                    )}

                    <Buttoncomp
                      id={deckstate.id}
                      className="buttonglob_css colortext1"
                      onClick={() => ModifPost(deckstate)}
                      message={
                        !buttonoff && Idamodif === deckstate.id
                          ? "Fermer"
                          : "Modifier"
                      }
                    />
                  </div>
                </form>
              </div>
            );
          })}
      </div>{" "}
    </>
  );
};

export default AffichageDeck;
