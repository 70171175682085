import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../helpers/AuthContext";
import Buttoncomp from "../../component/Buttoncomp";
import Navbarre from "../../component/Navbarre";
import NavbarreBase from "../../component/NavbarreBase";
import Deck from "./Deck";

function DeckFeed() {
  const [selectedDeck, setSelectedDeck] = useState(1);
  const [statemodif, setStatemodif] = useState(false);
  const [navbarreperso, setNavbarreperso] = useState(true);
  const [baseButtonText, setBaseButtonText] = useState("Base");

  const { authState } = useContext(AuthContext);

  const handleDeckClick = (deckNumber) => {
    setSelectedDeck(deckNumber);
  };

  useEffect(() => {
    // Actions à effectuer lorsque selectedDeck change
  }, [selectedDeck]);

  const toggleStatemodif = () => {
    setStatemodif((prevState) => !prevState);
  };

  const toggleNavbarreperso = () => {
    setNavbarreperso((prevState) => !prevState);
    setSelectedDeck(90);
    setBaseButtonText((prevState) => (prevState === "Base" ? "Perso" : "Base"));
  };

  return (
    <>
      <div className="alignementnav">
        <div className="alignement">
          <Buttoncomp
            onClick={toggleStatemodif}
            message={"Création"}
            className="buttondeck_css"
          />
          {authState.admin && (
            <Buttoncomp
              message={baseButtonText}
              className="buttondeck_css"
              onClick={toggleNavbarreperso}
            />
          )}
        </div>

       {/*  Apparition de NavBarre Perso ou de NavBarre de Base */}

        <div className="alignementnav">
          {navbarreperso ? (
            <Navbarre
              setSelectedDeck={setSelectedDeck}
              selectedDeck={selectedDeck}
            />
          ) : (
            <NavbarreBase
              setSelectedDeck={setSelectedDeck}
              selectedDeck={selectedDeck}
            />
          )}
        </div>
        <Deck
          deckNumber={selectedDeck}
          setSelectedDeck={setSelectedDeck}
          statemodif={statemodif}
        />
      </div>
    </>
  );
}

export default DeckFeed;
