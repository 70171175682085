import React from "react";
import { useHistory } from "react-router-dom";

function CartesCroixRet({ link, Deck }) {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: link,
      state: { Deck }
    });
  };

  return (
    <div className="croix" onClick={handleClick}>
      <div className="elementencartes">
        <div className="cartecpres">🎴</div>
        <div>
          <div className="cartecpres">🎴</div>
          <div className="cartecpres">🎴</div>
          <div className="cartecpres">🎴</div>
        </div>
        <div className="cartecpres">🎴</div>
      </div>

      <p>Tirage en croix, cartes à l'endroit et à l'envers</p>
    </div>
  );
}

export default CartesCroixRet;

