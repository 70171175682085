
import Buttoncomp from "./Buttoncomp";




const NavbarreBase = ({ setSelectedDeck, selectedDeck }) => {

/* 
  console.log("JE SUIS LA NAVBARRE DE BASE") */
  const handleDeckClick = (deckNumber) => {
    setSelectedDeck(deckNumber);
   
  };

  const handleDeckClickNumber = (deckNumber) => {
    setSelectedDeck(deckNumber);
  };


  return (
    <>   
    

      <div className="navbarre">
        
        <Buttoncomp
     
          title={"Bouton de Deck 1"}
          message={"Base 1"}

          className={selectedDeck === 90 ? "active" : "active2"}
        
          onClick={() => {
            handleDeckClickNumber(90);
          }}
        >
         
        </Buttoncomp>

        {/* Autres boutons de deck ici */}

        <Buttoncomp
          title={"Bouton de Deck 1"}
          className={selectedDeck === 91 ? "active" : "active2"}
          message={"Base 2"}
          onClick={() => {
            handleDeckClickNumber(91);
          }}
        >
         
        </Buttoncomp>

        <Buttoncomp
          title={"Bouton de Deck3 "}
          className={selectedDeck === 92 ? "active" : "active2"}
          message={"Base 3"}
          onClick={() => {
            handleDeckClickNumber(92);
          }}
        >
      
        </Buttoncomp>

        <Buttoncomp
          title={"Bouton de Deck4 "}
          className={selectedDeck === 93 ? "active" : "active2"}
          message={"Base 4"}
          onClick={() => {
            handleDeckClickNumber(93);
          }}
        >
        
        </Buttoncomp>

        <Buttoncomp
          title={"Bouton de Deck5 "}
          className={selectedDeck === 94 ? "active" : "active2"}
          message={"Base 5"}
          onClick={() => handleDeckClick(94)}
        >
        
        </Buttoncomp>
      </div>
    </>
  );
};

export default NavbarreBase;
