import axios from "axios";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { AuthContext } from "../../../helpers/AuthContext";
import AffichageDeck from "./AffichageDeck";
import AffichageFond from "./AffichageFond";
import FormulaireDeck from "./FormulaireDeck";
import AffichageDos from "./AffichageDos";
import AffichagePresentation from "./AffichagePresentation";
import { useHistory } from "react-router-dom";
import { apiUrl } from "../../../config";

const Deck = ({ deckNumber,statemodif }) => {
  const Keychange = deckNumber;
 

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, []);

  //verifiaction MAJ apres post
  const [MAJ, setMaj] = useState(false);

  const [MAJsup, setMajsup] = useState(true);

  //Pour afficher le deck
  const [deckstate, SetDeckstate] = useState([]);

  const [deckstate2, SetDeckstate2] = useState([]);

  const [deckstate3, SetDeckstate3] = useState([]);

  const [deckstate4, SetDeckstate4] = useState([]);

  //Le status des checkboxs

  const [checked, setChecked] = React.useState(false);

  const [checked2, setChecked2] = React.useState(false);

  const [checked3, setChecked3] = React.useState(false);

  const [backgroundoff, Setbackgroundoff] = useState(false);

  const [dosdecarteoff, Setdosdecarteoff] = useState(false);

  const [presentationoff, SetPresentationoff] = useState(false);

  //Recherche de l'id dans le context pour envoi en porter sur la fonction principale

  const { authState } = useContext(AuthContext);
  let history = useHistory();
  const id = authState.id;

  /* création du state fichier selectionné  */
  const fileSelected = (event) => {
    const filesize = event.target.files[0].size;

    // Variable + logique qui stocke la taille en KB
    if (filesize > 1000000) {
      console.log(filesize);
      alert("La fichier est trop volumineux il doit faire au maximum 1MO");
      settailledufichier(filesize);
      setIsSuccess(false);
      return;
    } else {
      const file = event.target.files[0];

      setIsSuccess(true);
      setFile(file);
      settailledufichier(filesize);
      console.log(filesize);
      console.log("FICHIER OK, peut être téléchargé  ");
    }
  };

  //La fonction envoyer

  //State du fichier selectionné

  const [tailledufichier, settailledufichier] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [file, setFile] = useState();

  const [SizeDeck, SetSizeDeck] = useState(false);

  // Set des champs text
  const [description, setDescription] = useState("");
  const [titre, setText] = useState("");

  const [majmodifpost, setmajmodifpost] = useState(true);

  const deletePost = async (deckstate) => {
    const lien = deckstate.lien;

    try {
      if (deckstate.id) {
        const result = await axios.delete(
          `${apiUrl}/postimages/${deckstate.id}`,
          {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
              lien,
            },
          }
        );

        setMajsup((majsup) => !majsup);

        return result.data;
      } else {
        console.log("L'id ne correspond pas");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("postText", description);
      formData.append("title", titre);

      formData.append("background", checked);

      formData.append("fond", checked2);

      formData.append("presentation", checked3);

      //Je vais chercher id dans le context
      formData.append("iduser", id);

      formData.append("numberofdeck", deckNumber);

     

      const result = await axios.post(
        `${apiUrl}/postimages/`,
        formData,
      
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
            "X-Deck-Number": deckNumber,
          },
        }
      );

      setMaj((maj) => !maj);

      setText("");

      setDescription("");

      setIsSuccess((isSuccess) => !isSuccess);

      settailledufichier("");
      /*  history.go("/deck2") */

      return result.data;
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    axios
      .get(
        `${apiUrl}/postimages/liredeck/${authState.id}/${deckNumber}`,

        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        SetDeckstate(response.data);

        if (response.data.length > 420) SetSizeDeck(true);
      });
  }, [majmodifpost, MAJ, authState, MAJsup, Keychange]);

  useEffect(() => {
    axios
      .get(
        `${apiUrl}/postimages/lirebackground/${authState.id}/${deckNumber}`,

        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        SetDeckstate2(response.data);

        Setbackgroundoff(response.data.length);
      });
  }, [majmodifpost, MAJ, authState, MAJsup, Keychange]);

  useEffect(() => {
    axios
      .get(
        `${apiUrl}/postimages/lireimagesdos/${authState.id}/${deckNumber}`,

        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        SetDeckstate3(response.data);

        Setdosdecarteoff(response.data.length);
      });
  }, [majmodifpost, MAJ, authState, MAJsup, Keychange]);

  useEffect(() => {
    axios
      .get(
        `${apiUrl}/postimages/lireimagespresentation/${authState.id}/${deckNumber}`,

        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        SetDeckstate4(response.data);

        SetPresentationoff(response.data.length);
      });
  }, [majmodifpost, MAJ, authState, MAJsup, Keychange]);

  return (
    <>
    

      {/* DEBUT AFFICHAGE DU FORMULAIRE */}

      <div className="affichageformulaireetliste">
        <div className="fromdiv">
          <div className="fromdivint">
            {statemodif && (
              <FormulaireDeck
                OnSubmit={submit}
                fileSelected={fileSelected}
                titre={titre}
                description={description}
                setText={setText}
                setDescription={setDescription}
                isSuccess={isSuccess}
                tailledufichier={tailledufichier}
                setChecked={setChecked}
                setChecked2={setChecked2}
                setChecked3={setChecked3}
                checked={checked}
                checked2={checked2}
                checked3={checked3}
                backgroundoff={backgroundoff}
                dosdecarteoff={dosdecarteoff}
                presentationoff={presentationoff}
                sizeDeck={SizeDeck}
              />
            )}
          </div>

          <div className="fromdicalques">
            {statemodif && (
              <>
                <div className="columnfrom">
                  <AffichageFond
                    MAJ={MAJ}
                    deckstate2={deckstate2}
                    deletePost={deletePost}
                    setMaj={setMaj}
                    maj={MAJ}
                    deckNumber={deckNumber}
                    SelectedDeck={deckNumber}
                  />

                  <AffichageDos
                    MAJ={MAJ}
                    deckstate3={deckstate3}
                    deletePost={deletePost}
                    setMaj={setMaj}
                    maj={MAJ}
                    deckNumber={deckNumber}
                    SelectedDeck={deckNumber}
                  />
                </div>

                <div>
                  <AffichagePresentation
                    MAJ={MAJ}
                    deckstate4={deckstate4}
                    deletePost={deletePost}
                    setMaj={setMaj}
                    maj={MAJ}
                    deckNumber={deckNumber}
                    SelectedDeck={deckNumber}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* FIN AFFICHAGE DU FORM */}

        <div>
          <AffichageDeck
            MAJ={MAJ}
            deckstate={deckstate}
            deletePost={deletePost}
            setMaj={setMaj}
            maj={MAJ}
            SelectedDeck={deckNumber}
          />
        </div>
      </div>
    </>
  );
};

export default Deck;
