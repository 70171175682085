import React from "react";
import { useHistory } from "react-router-dom";

function CartesAlignRetMod({ link, Deck }) {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: link,
      state: { Deck }
    });
  };

  return (

    <div  className="elementencartesligne">


<div className="carteunique" onClick={handleClick}>
  
  <div className="cartecpres">🎴</div>
  <div className="cartecpres">🎴</div>
  <div className="cartecpres">🎴</div>
  <div className="cartecpres">🎴</div>
  <div className="cartecpres">🎴</div>

<p>Tirage en ligne, cartes à l'endroit et à l'envers</p>




</div>


</div>

    
    
  );



}

export default CartesAlignRetMod;
