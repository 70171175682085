import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";

import { apiUrl } from "../config";


const PostsPage = () => {
  let history = useHistory();

  const { authState } = useContext(AuthContext);
  const [count, setCount] = useState(-1);

  const [username, setUsername] = useState("");

  const [listOfPosts, setListOfPosts] = useState([]);

  const [listOfPostsReves, setListOfPostsReves] = useState([]);
  const [listOfPostsFiche, setListOfPostsFiche] = useState([]);

  const listOfPostsslice = listOfPosts.slice(count);

  const listOfPostsRevesslice = listOfPostsReves.slice(count);
  const listOfPostsFicheSlice = listOfPostsFiche.slice(count);

  const id = authState.id;

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
    axios
      .get(`${apiUrl}/auth/basicinfo/${id}`)
      .then((response) => {
        {
          authState.username && setUsername(response.data.username);
        }
      });

    //Posts Tirages
    axios
      .get(`${apiUrl}/posts/byuserIdpriv/${id}`)
      .then((response) => {
        setListOfPosts(response.data);
      });
    //Posts Rêves
    axios
      .get(`${apiUrl}/posts/byuserId/${id}`)
      .then((response2) => {
        setListOfPostsReves(response2.data);
      });

    //Fiche
    axios
      .get(`${apiUrl}/posts3/byuserId/${id}`)
      .then((response3) => {
        setListOfPostsFiche(response3.data);
      });
  }, [id]);

  return (
    <div className="choix">
      <p><h1>Choisir une page de posts</h1></p>

      <div className="postpagefeed">
        <div className="">
          <div className="buttonglob_css">
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "white")}
              to={`/postpriv2/${authState.id}`}
            >
              <li><h2>Tirages </h2> </li>{" "}
            </NavLink>
          </div>
          <h3>Aperçu du dernier post Tirages</h3>
          <div className="boutonpriv">
            {" "}
            {authState.username === username && (
              <>
                <div className="listepostsutilisateurs">
                  {listOfPostsslice.map((value, key) => {
                    const date = new Date(value.createdAt);

                    return (
                      <div key={key} className="post3">
                        <div
                          className="title"
                          onClick={() => {
                            history.push(`/postsecondaire/${value.id}`);
                          }}
                        >
                          {" "}
                          {value.title}{" "}
                        </div>
                        <div
                          className="body"
                          onClick={() => {
                            history.push(`/postsecondaire/${value.id}`);
                          }}
                        >
                          {value.postText}
                        </div>
                        <div className="iframdiv">
                          <img
                            src={value.lien}
                            alt="Le tirage de carte et le fond sur lequel le tirage à été réalisé"
                          ></img>
                        </div>
                        <div className="categorie"> {value.categorie}</div>
                        <div className="footer">
                          <div className="textfooter">
                            {new Intl.DateTimeFormat("local").format(date)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>

        {/*  ////////////////////////////////////////////////////////////////////////// */}
        <div className="">
          <div className="buttonglob_css">
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "white")}
              to={`/profile/${authState.id}`}
            >
              <li><h2>Rêves</h2></li>{" "}
            </NavLink>
          </div>

          <h3>Aperçu du dernier post Rêves</h3>
          <div className="boutonpriv">
            {" "}
            {authState.username === username && (
              <>
                <div className="listepostsutilisateurs">
                  {listOfPostsRevesslice.map((value, key) => {
                    const date = new Date(value.createdAt);
                    return (
                      <div key={key} className="post3">
                        
                        <div
                          className="title"
                          onClick={() => {
                            history.push(`/post/${value.id}`);
                          }}
                        >
                          {" "}
                          {value.title}{" "}
                        </div>
                        <div
                          className="body"
                          onClick={() => {
                            history.push(`/post/${value.id}`);
                          }}
                        >
                          {value.postText}
                        </div>
                        <div className="iframdiv">
                          <img
                            src={value.lien}
                            alt="Le tirage de carte et le fond sur lequel le tirage à été réalisé"
                          ></img>
                        </div>
                        <div className="categorie"> {value.categorie}</div>
                        <div className="footer">
                          <div className="textfooter">
                            {new Intl.DateTimeFormat("local").format(date)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>

        {/* ////////////////////////////////////////////////////////////////////////////// */}

        <div className="">
          <div className="buttonglob_css">
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "white")}
              to={`/ComposantPorte/${authState.id}`}
            >
              <li><h2>Fiche</h2></li>{" "}
            </NavLink>
          </div>
        <h3>Aperçu de ma Fiche </h3>
          <div className="indiviFiche">
            <div className="post2">
              {authState.username === username && (
                <>
                  {listOfPostsFicheSlice.map((value, key) => {
                    const date = new Date(value.createdAt);
                    return (
                      <div key={key} className="">
                        <div
                          className="title"
                          onClick={() => {
                            history.push(`/lecture/${value.id}`);
                          }}
                        >
                          {" "}
                          <u>Mon objectif </u>
                        </div>
                        <div className="categorie">{value.title}</div>
                        <div
                          className="title"
                          onClick={() => {
                            history.push(`/lecture/${value.id}`);
                          }}
                        >
                          <u>Description </u>
                        </div>{" "}
                        <div className="categorie"> {value.postText}</div>
                        <div className="title">
                          <u> Spécialistes</u>{" "}
                        </div>
                        <div className="categorie">{value.lien}</div>
                        <div className="footer">
                          <div className="textfooter">
                            {new Intl.DateTimeFormat("local").format(date)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostsPage;
