import React, { Component } from "react";
import ReactPlayer from "react-player";

class Audio extends Component {
  render() {
    return (
      <div className="player">
        <ReactPlayer
       

          url="https://ia801803.us.archive.org/1/items/kai-engel-maree/KaiEngel-Maree.mp3"
          playing
          controls 
          width="60vw"
          height="2vw"
        />
      </div>
    );
  }
}

export default Audio;
