import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import axios from "axios";
import { useContext } from "react";

import { apiUrl } from "../config";

function Cartes() {

  const { authState } = useContext(AuthContext);
const [cartes, SetCartes] = useState();

const [cartes2, SetCartes2] = useState();
const [cartes3, SetCartes3] = useState();
const [cartes4, SetCartes4] = useState();
const [cartes5, SetCartes5] = useState();
  let history = useHistory();


  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, [cartes]);


  useEffect(() => {
     axios
      .get(
        `${apiUrl}/postimages/lireimagespresentation/${authState.id}/${1}`,

        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        SetCartes(response.data[0]);

        
       
      });
  }, []);  

  useEffect(() => {
    axios
     .get(
       `${apiUrl}/postimages/lireimagespresentation/${authState.id}/${2}`,

       {
         headers: { accessToken: localStorage.getItem("accessToken") },
       }
     )
     .then((response) => {
       SetCartes2(response.data[0]);

       
      
     });
 }, []); 

 useEffect(() => {
  axios
   .get(
     `${apiUrl}/postimages/lireimagespresentation/${authState.id}/${3}`,

     {
       headers: { accessToken: localStorage.getItem("accessToken") },
     }
   )
   .then((response) => {
     SetCartes3(response.data[0]);

     
    
   });
}, []); 
useEffect(() => {
  axios
   .get(
     `${apiUrl}/postimages/lireimagespresentation/${authState.id}/${4}`,

     {
       headers: { accessToken: localStorage.getItem("accessToken") },
     }
   )
   .then((response) => {
     SetCartes4(response.data[0]);

     
    
   });
}, []); 

useEffect(() => {
  axios
   .get(
     `${apiUrl}/postimages/lireimagespresentation/${authState.id}/${5}`,

     {
       headers: { accessToken: localStorage.getItem("accessToken") },
     }
   )
   .then((response) => {
     SetCartes5(response.data[0]);

     
    
   });
}, []); 



  return (
    <div className="choixcartestirage">
      <p> Veuillez choisir un style de cartes </p>

      <div className="calquesdeschoix">
      
         
            <>
              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartes',  { number: 1 });
                }}
              >
                

               { cartes &&  <> <img src={cartes.lien} alt="Le thème des cartes"/> <p>{cartes.title} </p></>
               }
              </div>
              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartes', { number: 2 });
                }}
              >
                { cartes2 &&  <> <img src={cartes2.lien} alt="Le thème des cartes"/> <p>{cartes2.title} </p></>
               }
              </div>

          
              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartes', { number: 3 });
                }}
              >
                { cartes3 &&  <> <img src={cartes3.lien} alt="Le thème des cartes"/> <p>{cartes3.title} </p></>
               }
              </div>


              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartes', { number: 4 });
                }}
              >
                { cartes4 &&  <> <img src={cartes4.lien} alt="Le thème des cartes"/> <p>{cartes4.title} </p></>
               }
              </div>
       

              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartes', { number: 5 });
                }}
              >
                { cartes5 &&  <> <img src={cartes5.lien} alt="Le thème des cartes"/> <p>{cartes5.title} </p></>
               }
              </div>
       </>
      
    
 
  
  </div></div>
  
  )}


export default Cartes;
