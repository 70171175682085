import React from "react";
import BoutonOk from "./component/BoutonOk";

class Formulairecartes extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  handleChange(event) {
    const donnee = event.target.value;
    localStorage.setItem("Maquestion", donnee);
  }

  render() {
    return (
      <form>
        <div className="question">
          <span>
            <input
              ref={this.inputRef}
              onChange={this.handleChange}
              maxLength="50"
              placeholder="                           ?"
            />
            <BoutonOk
              SetPageQuestion={this.props.SetPageQuestion}
              SetPageReponse={this.props.SetPageReponse}
            />
          </span>
        </div>
      </form>
    );
  }
}

export default Formulairecartes;
