import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import axios from "axios";
import { useContext } from "react";

import { apiUrl } from "../config";

function CartesBase() {

  const { authState } = useContext(AuthContext);
const [cartes, SetCartes] = useState();

const [cartes2, SetCartes2] = useState();
const [cartes3, SetCartes3] = useState();
const [cartes4, SetCartes4] = useState();
const [cartes5, SetCartes5] = useState();
  let history = useHistory();


  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, [cartes]);


  useEffect(() => {
     axios
      .get(
        `${apiUrl}/postimages/lireimagespresentationBase/${90}`,

        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        SetCartes(response.data[0]);

        
       
      });
  }, []);  

  useEffect(() => {
    axios
     .get(
       `${apiUrl}/postimages/lireimagespresentationBase/${91}`,

       {
         headers: { accessToken: localStorage.getItem("accessToken") },
       }
     )
     .then((response) => {
       SetCartes2(response.data[0]);

       
      
     });
 }, []); 

 useEffect(() => {
  axios
   .get(
     `${apiUrl}/postimages/lireimagespresentationBase/${92}`,

     {
       headers: { accessToken: localStorage.getItem("accessToken") },
     }
   )
   .then((response) => {
     SetCartes3(response.data[0]);

     
    
   });
}, []); 
useEffect(() => {
  axios
   .get(
     `${apiUrl}/postimages/lireimagespresentationBase/${93}`,

     {
       headers: { accessToken: localStorage.getItem("accessToken") },
     }
   )
   .then((response) => {
     SetCartes4(response.data[0]);

     
    
   });
}, []); 

useEffect(() => {
  axios
   .get(
     `${apiUrl}/postimages/lireimagespresentationBase/${94}`,

     {
       headers: { accessToken: localStorage.getItem("accessToken") },
     }
   )
   .then((response) => {
     SetCartes5(response.data[0]);

     
    
   });
}, []); 



  return (
    <div className="choixcartestirage">
      <p> <h1>Veuillez choisir un style de cartes</h1> </p>

      <div className="calquesdeschoix">
      
        
            <>
            <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartesBase',  { number: 90 });
                }}
              >
                

               { cartes &&  <> <img src={cartes.lien} alt="Le thème des cartes"/> <p>{cartes.title} </p></>
               }
              </div>
              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartesBase',  { number: 91 });
                }}
              >
                { cartes2 &&  <> <img src={cartes2.lien} alt="Le thème des cartes"/> <p>{cartes2.title} </p></>
               }
              </div>

          
              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartesBase',  { number: 92 });
                }}
              >
                { cartes3 &&  <> <img src={cartes3.lien} alt="Le thème des cartes"/> <p>{cartes3.title} </p></>
               }
              </div>


              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartesBase',  { number: 93 });
                }}
              >
                { cartes4 &&  <> <img src={cartes4.lien} alt="Le thème des cartes"/> <p>{cartes4.title} </p></>
               }
              </div>
       

              <div
                className="carte"
                onClick={() => {
                  history.push('/FormCartesBase',  { number: 94 });
                }}
              >
                { cartes5 &&  <> <img src={cartes5.lien} alt="Le thème des cartes"/> <p>{cartes5.title} </p></>
               }
              </div>
       </>
      
    
 
  
  </div></div>
  
  )}


export default CartesBase;
