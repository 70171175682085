import React from "react";

import { useEffect } from "react";

import { useHistory } from "react-router-dom";

function FormulaireDeck({
  OnSubmit,
  fileSelected,
  titre,
  description,
  setText,
  setDescription,
  isSuccess,
  tailledufichier,
  setChecked,
  setChecked2,
  setChecked3, 
  checked,
  checked2,
  checked3, 
  backgroundoff,
  dosdecarteoff,
  presentationoff,
  sizeDeck,
  

}) {
  const handleChange = () => {

    setChecked(!checked);
   
  };

  const handleChange2 = () => {
 
    setChecked2(!checked2);
    
  };


  const handleChange3 = () => {
   
    setChecked3(!checked3); 
   
  };

  let history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, [history]);



  return (
    <>
      <div className="createPostPageDeck">
        <div className="formContainer2">
          <form onSubmit={OnSubmit}>
            <div className="titre"><h1>Ajout de cartes</h1></div>
            <label><h2>Titre de l'image: </h2></label>
            <input
              value={titre}
              onChange={(i) => setText(i.target.value)}
              type="text"
              placeholder="(Facultatif) Peut être laissé vide"
              minLength="0"
              maxLength="250"
            />
            <label><h3>Description de l'image: </h3></label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              id="text"
              minLength="0"
              maxLength="2250"
              size="10"
              placeholder="(Facultatif) Peut être laissé vide"
            />
            <label><h3>Ajouter une image:</h3> </label>
            <input
              onChange={fileSelected}
              type="file"
              accept="image/*"
              id="image"
              
              required
             
            />

            {sizeDeck && <div>Vous avez déjà ajouté 420 cartes</div>}
            {isSuccess ? !sizeDeck && (
              <button type="submit">Submit</button>
            ) : (
             <div className="lienpoid">Veuillez selectionner un fichier de moins de 1MO ce fichier fait : </div> 
              
              
            )}{tailledufichier / 1000} ko
            {!isSuccess &&  <a href="https://compressor.io/ " className="lien" target="_blank">Pour alléger 70% du poid d'une image il existe des outils telque <span>compressor.io 
              
         </span><div  className="lien">   Vous pouvez ajouter jusqu'a 400 cartes</div></a>  
           
            }
            
            <p></p>
            <div className="alignement">

            { !checked2 && !checked && !presentationoff&& (
                <div>
                  Presentation{" "}
                  <input
                    type="checkbox"
                    checked={checked3}
                    onChange={handleChange3}
                  />
                </div>
              )}
              {!checked2 && !backgroundoff && !checked3 && (
                <div>
                  Fond d'écran{" "}
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                  />
                </div>
              )}

              {!checked && !dosdecarteoff  && !checked3 && (
                <div>
                  Dos de cartes{" "}
                  <input
                    type="checkbox"
                    checked={checked2}
                    onChange={handleChange2}
                  />{" "}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default FormulaireDeck;
