import React from "react";

class FlavorForm extends React.Component {
  myFunction() {
    this.props.history.push("/anim");
  }

  handleChange(event) {
    const donnee = event.target.value;

    this.setState({ value: donnee });

    localStorage.setItem(
      "Maquestion",

      donnee
    );
  }

  handleSubmit(event) {
    const donnee = this.state.value;

    console.log("donnee");

    console.log(donnee);
    event.preventDefault();
  }

  constructor(props) {
    localStorage.removeItem("Maquestion");
    super(props);
    this.state = this.handleChange;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <form>
        <div className="formulaire">
          <div>
            <input
           
              value={this.state.value}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              maxLength="50"
              placeholder="                           ?"
            ></input>
          </div>
          {console.log("this.handleSubmit")}
          {console.log(this.state)}
        </div>
      </form>
    );
  }
}
export default FlavorForm;
