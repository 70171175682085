import React, {useEffect } from "react";
import { useHistory } from "react-router-dom";
import bougie from "../../../logos/bougie.gif";
import CartesCroixRet from "./FormsTirages/CartesCroixRet";
import CartesCroixMod from "./FormsTirages/CartesCroixMod";
import CarteUniqueMod from "./FormsTirages/CarteUniqueMod";
import CarteUniqueRetMod from "./FormsTirages/CarteUniqueRetMod";
import CartesAlignMod from "./FormsTirages/CartesAlignMod";
import CartesAlignRetMod from "./FormsTirages/CartesAlignRetMod";

function FormCartes(props) {



  const { number } = props.location.state;
  const lien = `/QuestionMesCartes${number}`;
 console.log(lien, "Lien")
 console.log(number,"NUMBER")
  let history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, [number]);
  return (
    <div className="imagesmodels">
      <p> Veuillez choisir un modèle de tirage</p>

      <div className="horizon">

<CartesCroixMod link={"/QuestionMesCartes"} Deck={number} />
<CartesCroixRet link={"/QuestionMesCartesRet"} Deck={number}/>
<CarteUniqueMod link={"/QuestionMesCartesUnique"} Deck={number}/>
<CarteUniqueRetMod link={"/QuestionMesCartesUniqueRet"} Deck={number}/>
<CartesAlignMod link={"/QuestionMesCartesAlign"} Deck={number}/>
<CartesAlignRetMod link={"/QuestionMesCartesAlignRet"} Deck={number}/>
</div>

 
<div
        className="bougie"
        onClick={() => {
          history.push(`/CartesBase`);
        }}
      >
        <img src={bougie} />

        <p>Retour</p>
      </div>


    </div>
  );
}

export default FormCartes;
