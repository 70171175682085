import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../helpers/AuthContext";
import Buttoncomp from "../../component/Buttoncomp";
import ConfirmationModale from "../../UI/ConfirmationModale";
import ConfirmationModalmodif from "../../UI/ConfirmationModalemodif";



import { apiUrl } from '../../../config';



const AffichagePresentation =  ({deckstate4, deletePost, setMaj,  deckNumber, SelectedDeck}) => {

  let history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, []);


  const [tailledufichier, settailledufichier] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [file, setFile] = useState();
  const [confirmationModal, setconfirmationModal] = useState({});

  const [confirmationModal2, setconfirmationModal2] = useState({});
  const [titre, settitre] = useState(""); 
  const [text, setText] = useState("");

  const [idamodifmodal,setidamodifmodal] = useState("")
  
  const [idamodifmodal2,setidamodifmodal2] = useState("")
  
  const [maj2, setMaj2] = useState(false);

  const [InputOn, setInputOn] = useState(false);
  const [buttonoff,setbuttonoff] = useState(true)
  const [Idamodif,setIdamodif] = useState("")


  const ModifPost = async (deckstate4) => {

    


    setIdamodif(deckstate4.id)

  
    setInputOn((InputOn)=> !InputOn)


  
  
setbuttonoff((buttonoff)=> !buttonoff)
   
   
    };

  const confirmationModalHandler = async  (deckstate4) => {
  
    console.log("je suis dans confirmationModalHandler");
    
   
    setconfirmationModal({
     
    title:"Confirmer de la suppression du message ?",
   
    message: `Voulez-vous vraiment supprimer ce poste qui à pour titre : ${deckstate4.title} ? `,

    lien : deckstate4.lien ,
    
    });
    setidamodifmodal(deckstate4.id)
    return
    

    };

    const confirmationModalHandler2 = async  (deckstate4) => {
  
     /*  console.log("je suis dans confirmationModalHandler"); */
      
     
      setconfirmationModal2({
       
      title:"Confirmer la modification du message ?",
     
      message: `Voulez-vous vraiment modifier ce poste qui à pour titre : ${deckstate4.title} ? `,
      lien : deckstate4.lien ,
      
      });
      setidamodifmodal2(deckstate4.id)
      return
      
  
      };

  
    const { authState } = useContext(AuthContext);


    const submit = async (deckstate4) => {
     
      
  
      
  

     


     
      try{
  const ancienfichier = deckstate4.lien.toString()




        const id = authState.id
    const formData = new FormData();
    formData.append("image", file);
    formData.append("postText", titre);
    formData.append("title", titre);
    formData.append("ancienfichier", ancienfichier);
    formData.append("postId", deckstate4.id);
  

  formData.append("iduser", id );
  
   //Voir si cette partie est utiliser : 1 est possible
   formData.append("numberofdeck", deckNumber);
  ;

  
        const result = await axios.put(
          `${apiUrl}/postimages/`, 
          
          formData,
          
         
          {
            headers: {
              
              accessToken: localStorage.getItem("accessToken"),
              ancienfichier,
              "x-deck-number": SelectedDeck,
              "x-deck-numberasupr":   ancienfichier, 
            },
          }
        );
      

          setbuttonoff((buttonoff)=> !buttonoff)
          setInputOn((InputOn)=> !InputOn)
          setconfirmationModal2((confirmationModal2)=> !confirmationModal2)
          setconfirmationModal((confirmationModal)=> !confirmationModal)
          setMaj((maj)=> !maj)
          return result.data;
       
        
       
       
      
       
      }catch(error){
      
      console.log(error)
      }
    
    
    };
  
    const fileSelected = (event) => {
      const filesize = event.target.files[0].size;
  
      if (filesize > 1000000) {
        console.log(filesize);
        alert("La fichier est trop volumineux il doit faire au maximum 800 KO");
        setIsSuccess(false);
        settailledufichier(filesize);
        return;
      } else {
        const file = event.target.files[0];
  
        setIsSuccess(true);
        setFile(file);
        settailledufichier(filesize);
        console.log(filesize);
        console.log("FICHIER OK, peut être téléchargé  ");
      }
    };

    useEffect(  () => {

   
     
     setMaj((maj)=> !maj)
    
  
    }, [maj2]);



    const orderDisplayMessage = 
   deckstate4 &&  deckstate4.sort((a,b) => {
      

return (b.id - a.id
    

    
    )


    });





return (<> <div className="imagedeckdos">
   
    {orderDisplayMessage && orderDisplayMessage.map((deckstate, key) => {
     
        return (

          
          <div key={key} className="">
{confirmationModal && idamodifmodal=== deckstate.id &&
        
        < ConfirmationModale 
        title = {confirmationModal.title}
        message = {confirmationModal.message}
        Onconfirm = {() => setconfirmationModal(null)}
        OnconfirmDelete = {() => deletePost(deckstate)}
        lien={confirmationModal.lien} 
        
        />}

{confirmationModal2 && idamodifmodal2=== deckstate.id &&
        <ConfirmationModalmodif
        
        title = {confirmationModal2.title}
        message = {confirmationModal2.message}
        Onconfirm = {() => setconfirmationModal2(null)}
        OnconfirmDelete = {() => submit(deckstate)}
        lien={confirmationModal2.lien} 
        
        />}

           <form onSubmit={submit}>


        

            <label ><p>Presentation</p>  </label>

  {      InputOn && Idamodif=== deckstate.id && 
  
  
  <input
            value={titre}
            onChange={(e) => settitre(e.target.value)}
            type="text"
            required
            placeholder={deckstate.title}
            size="100"
          />



}
         

            {      InputOn&&  Idamodif=== deckstate.id &&  
            
            
            
            
           

<input
value={text}
onChange={(i) => setText(i.target.value)}
type="text"
id="text"
minLength="4"
maxLength="200"
size="150"
placeholder={deckstate.postText}

/>

}
        
          <img src= {deckstate.lien} /> 


          {      InputOn &&  Idamodif=== deckstate.id &&  
          
          
          
          <input
            onChange={fileSelected}
            type="file"
            accept="image/*"
            id="image"
            required
          />}

  
<div className="alignement">
       {!InputOn  &&  <Buttoncomp className="buttonglob_css" onClick={() => confirmationModalHandler(deckstate)} message={"Suprimer"} /> }

        
    


        { InputOn && Idamodif === deckstate.id &&  isSuccess && <Buttoncomp id={deckstate.id} className="buttonglob_css" type="submit" onClick={() => confirmationModalHandler2(deckstate)} message={"Envoyer"} />}

           <Buttoncomp id={deckstate.id} className="buttonglob_css colortext1" onClick={() => ModifPost(deckstate)} message={!buttonoff && Idamodif=== deckstate.id ? "Fermer": "Modifier"} />

           </div>
          
           </form></div>
      
            
        );
      })}



</div> </>
)













}

export default AffichagePresentation;